export const colors = {
  coral: "#F4484B",
  pink: "#EDA8A8",
  purple: "#5C415D",
  teal: "#00BFB2",
  white: "#FFFFFF",
  lightGray: "#E2E2E2",
}

export default colors
